import {
    Button, Dropdown, MenuProps, Space,
} from 'antd';
import React from 'react';
import './adminHeader.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DownOutlined } from '@ant-design/icons';

interface HeaderProps {
    displayLogo?: boolean;
    className?: string;
}

export default function AdminHeader({ displayLogo = true, className = 'header' }: Readonly<HeaderProps>) {
    const [t, i18n] = useTranslation('common');
    const items: MenuProps['items'] = [
        {
            key: 'fr',
            label: 'Français',
        },
        {
            key: 'en',
            label: 'English',
        },
        {
            key: 'de',
            label: 'Deutsch',
        },
        {
            key: 'es',
            label: 'Español',
        },
    ];

    const onClick: MenuProps['onClick'] = ({ key }) => {
        i18n.changeLanguage(key);
    };

    return (
        <div className={className}>
            {displayLogo && (
                <>
                    <img className="logoDesktop" alt="Desktop logo" />
                    <img className="logoMobile" alt="Mobile logo" />
                </>
            )}
            <Dropdown className="languageDropdown" menu={{ items, onClick }}>
                <Button>
                    <Space>
                        {t('language')}
                        <FontAwesomeIcon icon="language" />
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
        </div>
    );
}
